import React from 'react';
import dayjs from 'dayjs';

import { DynamicContentComponent } from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { BigTextHeader } from '@lerna-monorepo/common/components/bigTextHeader/bigTextHeader.component';
import { DynamicContentHeader } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { DynamicContentComponentContainer } from '../aboutUs/aboutUs.styled';
import { LanguageSlug } from '@lerna-monorepo/common/enums/languageSlug.enum';

import { SingleNewsPageData } from './singleNews.types';
import { DynamicContentHeaderType } from '@lerna-monorepo/common/enums/dynamicContentHeaderType.enum';

const SingleNewsPage: React.FC<SingleNewsPageData> = (
  pageData: SingleNewsPageData
) => {
  const data = pageData.pageContext.data;
  const getPrefixPhrase = () => {
    const lang = data.language?.slug;
    if (lang === LanguageSlug.De) {
      return 'Hinzugefügt';
    } else if (lang === LanguageSlug.En) {
      return 'Added';
    } else if (lang === LanguageSlug.Ru) {
      return 'Добавлено';
    } else {
      return 'Dodano';
    }
  };

  const dynamicContentHeaderObject: DynamicContentHeader = {
    bigHeader: {
      descriptionContent: `${getPrefixPhrase()}: ${dayjs(data.date).format(
        'DD.MM.YYYY'
      )}`,
      mainContent: null,
    },
    smallImageHeader: {
      text: null,
      photo: null,
    },
    headerType: DynamicContentHeaderType.BigTextHeader,
  };

  return (
    <>
      <BigTextHeader content={data.title} />
      <DynamicContentComponentContainer>
        <DynamicContentComponent
          header={dynamicContentHeaderObject}
          dynamicContent={data.acf.newsData.dynamicContent}
        />
      </DynamicContentComponentContainer>
    </>
  );
};

export default SingleNewsPage;
